













import { defineComponent, watchEffect } from '@vue/composition-api'
import { useSpotify } from '@/hooks/useSpotify'

export default defineComponent({
  name: 'SpotifyMusic',
  props: {
    play: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const onPlay = () => {
      if (!props.play) {
        emit('play')
      }
    }

    const onPause = () => {
      if (props.play) {
        emit('pause')
      }
    }
    const { ready, play, pause } = useSpotify(onPlay, onPause)

    watchEffect(() => {
      props.play ? play() : pause()
    })

    return { ready, play, pause }
  }
})
